import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import '../styles/your-career.scss';
import career1 from '../assets/img/career1.jpg';
import career2 from '../assets/img/career2.jpg';
import career3 from '../assets/img/career3.jpg';
import career4 from '../assets/img/career4.jpg';
import career5 from '../assets/img/career5.jpg';
import career6 from '../assets/img/career6.jpg';

export default function YourCareerPage() {
  return (
    <>
      <Navbar route="/your-career" />

      <main>
        <Hero
          video="your-career"
          title='We work shoulder-to-shoulder.'
          text={<><span>We</span> become partners both for our clients and our team-mates.</>}
        />

        <section className="career__heading">
          <h3>Why work with us?</h3>
          <span className="line"></span>
          <p>We believe our people are key to our success, and we would like to be key to yours. As a consultant in TwinPikes, you will work side by side with peers and managers, in a broad variety of organizations to enhance and transform the way they do business. As your career moves forward, you will typically be responsible for managing several work streams and improvement areas.</p>
        </section>

        <section className="career__features">
          <div className="career__feature">
            <div className="img">
              <img src={career1} alt='' />
            </div>

            <div className="text">
              <h3>Steep career development</h3>
              <span className="line"></span>
              <p>TwinPikes is a rapidly growing company and thus your career as a consultant at TwinPikes might develop at a high pace as we expect you to assume new responsibilities fast. We guarantee that nobody will slow you down or bureau-capture you.</p>
            </div>
          </div>

          <div className="career__feature">
            <div className="img">
              <img src={career2} alt='' />
            </div>

            <div className="text">
              <h3>Comprehensive business experience</h3>
              <span className="line"></span>
              <p>At TwinPikes, we relentlessly work to multiply our client&apos;s top and bottom line growth. You will be offered the opportunity to develop professionally across a wide range of areas. Delivering real and tangible results is a common denominator for all.</p>
            </div>
          </div>

          <div className="career__feature">
            <div className="img">
              <img src={career3} alt='' />
            </div>

            <div className="text">
              <h3>International exposure</h3>
              <span className="line"></span>
              <p>A large part of our business is based on projects across the world - Africa, Australia, Europe, and the Americas - where you will gain international exposure and business experience.</p>
            </div>
          </div>

          <div className="career__feature">
            <div className="img">
              <img src={career4} alt='' />
            </div>

            <div className="text">
              <h3>Direct involvement from day one</h3>
              <span className="line"></span>
              <p>At TwinPikes, we like execution and results rather than story-telling-day-dreaming. Thus, in line with our hands-on style and collaborative culture, we offer you the chance to make an immediate impact on our clients&apos; most important challenges. You will be a key resource in the realization of our strategy, taking TwinPikes to the next level.</p>
            </div>
          </div>

          <div className="career__feature">
            <div className="img">
              <img src={career5} alt='' />
            </div>

            <div className="text">
              <h3>Diversity & inclusion</h3>
              <span className="line"></span>
              <p>Our commitment to diversity is critical to lasting performance improvements thus generating the maximum value for our clients. We employ professionals with different backgrounds, creating a multicultural and stimulating environment.</p>
            </div>
          </div>

          <div className="career__feature">
            <div className="img">
              <img src={career6} alt='' />
            </div>

            <div className="text">
              <h3>YoungPikers</h3>
              <span className="line"></span>
              <p>At TwinPikes, we draw on each other&apos;s capacities and experiences, to inspire and motivate one another. Our young consultants&apos; club is known as the YoungPikers, which is an informal environment to socialize, on-board new consultants, discuss current cases and learn from one another.</p>
            </div>
          </div>
        </section>

        <section className="career__banner">
          <div>
            <h2>Are you brave enough to challenge an unambitious status quo?</h2>
            <a href="https://jobs.twinpikes.com/jobs" target='_blank' rel='noreferrer'>Apply Here</a>
          </div>
        </section>

        <section className="career__lookingFor">
          <h3>What are we looking for?</h3>
          <span className="line"></span>
          <div>
            <p>TwinPikes is comprised of extraordinarily talented individuals who share a passion for achieving the very best in everything they do. We are looking for forward-thinking, inspired people who can develop and implement creative solutions to challenging problems and work with their peers and our clients to achieve it.</p>
            <p>Our professionals have typically shown great results in previous work or academic experience and come from many different disciplines—not only business and economics, but also engineering, biology or law. Often, they also have an MSC or MBA level of education.</p>
            <p>We expect our consultants to be fluent in at least two languages and be willing to do projects abroad.</p>
          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}