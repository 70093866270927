import React from 'react';
import HomePage from './pages/HomePage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
// import OurWorkPage from './pages/OurWorkPage';
import YourCareerPage from './pages/YourCareerPage';
// import ContactUsPage from './pages/ContactUsPage';
import InsightPage from './pages/InsightPage';
import OurTeamPage from './pages/OurTeamPage';
import OurInsightsPage from './pages/OurInsightsPage';
// import OurSpiritPage from './pages/OurSpiritPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/our-work" element={<OurWorkPage />} /> */}
        <Route path='/our-team' element={<OurTeamPage />} />
        <Route path='/your-career' element={<YourCareerPage />} />
        {/* <Route path='/contact-us' element={<ContactUsPage />} /> */}
        <Route path='/our-insights' element={<OurInsightsPage />} />
        <Route path='/our-insights/:insightId' element={<InsightPage />} />
        {/* <Route path='/our-spirit' element={<OurSpiritPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
