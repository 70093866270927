import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ModalVideo from 'react-modal-video';
import { useState } from "react";
import videoCover from '../assets/img/video_cover.jpg';
import '../styles/home.scss';
import Hero from "../components/Hero";
import Link from "../components/Link";
import center1 from '../assets/img/center1.jpg';
import center2 from '../assets/img/center2.jpg';
import center3 from '../assets/img/center3.jpg';
import center4 from '../assets/img/center4.jpg';
import center5 from '../assets/img/center5.jpg';
// import down from '../assets/img/down.png';

export default function HomePage() {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <Navbar route="/" />

      <main>
        <Hero />

        <section className='home__about'>
          <div className='video'>
            <ModalVideo channel='custom' url='/assets/video/teaser.mp4' isOpen={modalOpened} onClose={() => setModalOpened(false)} />
            <div className='img' onClick={() => setModalOpened(true)}>
              <img src={videoCover} alt='' />
            </div>
          </div>

          <div className='content'>
            <h3>We work shoulder to shoulder with you to transform established ways of working and achieve strong and sustainable top and bottom line growth.</h3>
            <span className='line'></span>
            <p>We work with ambitious leaders and organizations to develop and implement strategies, enabling their organisation to reach new heights and deliver substantial and sustainable performance improvements.<br />We believe our contribution should always be about significant, measurable results, and we always seek to make the change and improvement process a positive and stimulating learning experience to achieve tangible and durable results.</p>
          </div>
        </section>

        {/* <section className='home__story'>
          <h3>Our Story</h3>
          <span className='line'></span>
          <p>TwinPikes is a management consulting firm with international reach stemming from Bene Agere, which was established in Norway in 2005. In Spain, Bene Agere started operations in 2011, and has been growing rapidly at an annual rate of 30% to 40%.<br />In 2018, we became TwinPikes to reflect our collaborative and passionate spirit, working alongside our clients shoulder-to-shoulder and sharing common objectives to achieve outstanding results.</p>
        </section>

        <section className='home__story2'>
          <h3>We are constantly challenging ourselves and thinking beyond normal conventions.</h3>
          <span className='line'></span>
          <p>&quot;Challenge the Do&apos;s&quot; is inherent to the way we work and behave. Our engagements aim at doing the right thing to transform established rules and deliver exceptional results for our clients.</p>
          <Link href='/our-work' className='button'>
            <span>LEARN MORE</span>
            <span className='line'></span>
          </Link>
        </section> */}

        {/* <div className="home__learn-more">
          <a href="#our-work">
            <img src={down} alt="" />
            <span>LEARN MORE</span>
          </a>
        </div> */}

        <div className="separator">
          <span></span>
        </div>

        <section id='our-work' className="work__heading">
          <h1>We do the right thing.</h1>
          <span className='line'></span>
          <p>Our deliverables are not just reports or presentations, but reaching results!</p>
        </section>

        <section className="work__centers">
          <div className="header">
            <h3>At TwinPikes we organize our capabilities to transform organizations to boost results on &quot;Growth&quot;, &quot;Efficiency&quot;, &quot;Supply Chain and Operations&quot;, &quot;Business Driven Technology&quot; and &quot;Data&quot; which will have a measurable impact in the P&L.</h3>
          </div>

          <div className="centers">
            <div className="center">
              <div className="img">
                <img src={center4} alt="" />
              </div>
              <span className="line"></span>
              <div className="text">
                <h4>BUSINESS DRIVEN TECHNOLOGY</h4>
                <div className="container">
                  <div className="content">
                    <h5>IT as a Business Partner</h5>
                    <ul>
                      <li><span></span>IT Strategy</li>
                      <li><span></span>Business Architecture</li>
                      <li><span></span>Business Operating Model definition</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Improve IT effectiveness</h5>
                    <ul>
                      <li><span></span>Architecture Governance</li>
                      <li><span></span>Demand Management</li>
                      <li><span></span>Delivery Optimization - Shift to Agile, Self-Service, TCO, etc.</li>
                      <li><span></span>Supplier Consolidation</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Technology as a competitive advantage</h5>
                    <ul>
                      <li><span></span>Architecture Reference models</li>
                      <li><span></span>Automation as a Service</li>
                      <li><span></span>Setup Innovation framework</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Grow your Customers & Consumers</h5>
                    <ul>
                      <li><span></span>Align CRM capabilities to business needs</li>
                      <li><span></span>IOT and Digital Business Architecture</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Protect organization Assets</h5>
                    <ul>
                      <li><span></span>CyberSecurity Strategy</li>
                      <li><span></span>Infrastructure and Security alignment</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="center">
              <div className="img">
                <img src={center5} alt="" />
              </div>
              <span className="line"></span>
              <div className="text">
                <h4>DATA</h4>
                <div className="container">
                  <div className="content">
                    <h5>Data strategy & Innovation</h5>
                    <ul>
                      <li><span></span>Use case discovery & solutioning and P&L impact</li>
                      <li><span></span>Data demand management & delivery capability design</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Single View of Truth</h5>
                    <ul>
                      <li><span></span>M&A ERP Integration</li>
                      <li><span></span>Master Data as a Service</li>
                      <li><span></span>Data governance, functional & architecture design</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Data Culture</h5>
                    <ul>
                      <li><span></span>Data literacy program</li>
                      <li><span></span>Data x managers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="center">
              <div className="img">
                <img src={center2} alt="" />
              </div>
              <span className="line"></span>
              <div className="text">
                <h4>EFFICIENCY</h4>
                <div className="container">
                  <div className="content">
                    <h5>Processes Standardization and optimization</h5>
                    <ul>
                      <li><span></span>Process mapping, identification of operational improvements</li>
                      <li><span></span>Process automation and optimization</li>
                      <li><span></span>Training and coaching</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Organization and restructuring</h5>
                    <ul>
                      <li><span></span>Optimisation of complex organisational structures</li>
                      <li><span></span>Definition of key responsibilities at HQ, Divisional and Local levels</li>
                      <li><span></span>Alignment of organisations with strategy</li>
                      <li><span></span>Reduction of duplication</li>
                      <li><span></span>Creation of shared services</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="center">
              <div className="img">
                <img src={center1} alt="" />
              </div>
              <span className="line"></span>
              <div className="text">
                <h4>GROWTH</h4>
                <div className="container">
                  <div className="content">
                    <h5>Growth strategies</h5>
                    <ul>
                      <li><span></span>Medium- and long-term strategic and commercial plans</li>
                      <li><span></span>Mergers and acquisitions, investment analysis and negotiation</li>
                      <li><span></span>Post-merger integrations</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Sales and Commercial effectiveness in B2B</h5>
                    <ul>
                      <li><span></span>Sales and commercial effectiveness (account planning, segmentation, sales coaching…)</li>
                      <li><span></span>Pricing and Trade terms  policy definition and implementation</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Retail growth strategies and B2C</h5>
                    <ul>
                      <li><span></span>Commercial effectiveness in a retail environment (Pricing, Promotions, Assortment, shop layout, X and Up-selling, etc.)</li>
                      <li><span></span>Implementation of digital tools</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="center">
              <div className="img">
                <img src={center3} alt="" />
              </div>
              <span className="line"></span>
              <div className="text">
                <h4>SUPPLY CHAIN & OPERATIONS</h4>
                <div className="container">
                  <div className="content">
                    <h5>Optimizing the supply chain model</h5>
                    <ul>
                      <li><span></span>Global assessment of Supply Chain performance</li>
                      <li><span></span>Logistics Network strategy (warehousing and transport)</li>
                      <li><span></span>Sales and Operations Planning (S&OP)</li>
                      <li><span></span>IT systems and tools</li>
                      <li><span></span>Global Master data integration and standardization</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>Improving Supply Chain efficiency</h5>
                    <ul>
                      <li><span></span>Increased service level and stock availability</li>
                      <li><span></span>Inventory reduction</li>
                      <li><span></span>Optimisation of operating costs</li>
                    </ul>
                  </div>

                  <div className="content">
                    <h5>After-Sales Service</h5>
                    <ul>
                      <li><span></span>Standardisation of services and KPIs</li>
                      <li><span></span>Reputation management</li>
                      <li><span></span>After-sales as a business driver</li>
                      <li><span></span>Cost and revenue management</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='home__team'>
          <div className='container'>
            <h3>It feels different to work with us</h3>
            <span className='line'></span>
            <p>Our uniquely collaborative and passionate people work alongside our clients every step of the way. Our consulting teams are directly involved with our clients&apos; teams, working shoulder-to-shoulder with them in their facilities and sharing common objectives to achieve outstanding results.</p>
            <Link href='/our-team' className='button'>
              <span>MEET OUR TEAM</span>
              <span className='line'></span>
            </Link>
          </div>
        </section>

        <section className='home__doYouWant'>
          <div className='side'>
            {/* <h3>Do you want to take your business to the next level?</h3>
            <Link href='mailto:twinpikes@twinpikes.com' className='button'>
              <span>LET&apos;S WORK TOGETHER</span>
            </Link> */}
            <video className="video" autoPlay loop muted preload="true">
              <source src="/assets/video/your-career.mp4" type='video/mp4'/>
            </video>
          </div>

          <span className='divider'></span>

          <div className='side'>
            <h3>Do you want to be a Piker?</h3>
            <a href='/your-career' className='button'>
              <span>COME WORK WITH US</span>
            </a>
          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}