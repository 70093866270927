import { useEffect, useState } from 'react';
import '../styles/navbar.scss';
import logo from '../assets/img/logo.svg';
import logoGold from '../assets/img/logo_gold.svg';
import Link from './Link';

export default function Navbar({ route }: { route: '/' | '/our-work' | '/our-team' | '/our-spirit' | '/your-career' | '/contact-us' | '/our-insights' }) {
  const [opened, setOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <nav>
    <div className='margin'></div>

    <div className={'container ' + (scrolled ? 'scrolled' : '')}>
      <div className='wrapper'>
        <a href='/'>
          <div className='logo'>
            <img src={scrolled ? logo : logoGold} alt='' />
          </div>
        </a>

        <div className='links'>
          <Link href='/' className={'link ' + (route === '/' ? 'active' : '')}>
            <span>About</span>
            <span>Twinpikes</span>
            <span className='line'></span>
          </Link>
          {/* <Link href='/our-work' className={'link ' + (route === '/our-work' ? 'active' : '')}>
            <span>What</span>
            <span>We Do</span>
            <span className='line'></span>
          </Link> */}
          <Link href='/our-team' className={'link ' + (route === '/our-team' ? 'active' : '')}>
            <span>Who</span>
            <span>We are</span>
            <span className='line'></span>
          </Link>
          {/* <Link href='/our-spirit' className={'link ' + (route === '/our-spirit' ? 'active' : '')}>
            <span>How</span>
            <span>We do it</span>
            <span className='line'></span>
          </Link> */}
          <Link href='/your-career' className={'link ' + (route === '/your-career' ? 'active' : '')}>
            <span>Your</span>
            <span>Career</span>
            <span className='line'></span>
          </Link>
          {/* <Link href='/contact-us' className={'link ' + (route === '/contact-us' ? 'active' : '')}>
            <span>Get in</span>
            <span>Contact</span>
            <span className='line'></span>
          </Link> */}
          <Link href='/our-insights' className={'link ' + (route === '/our-insights' ? 'active' : '')}>
            <span>Our</span>
            <span>Insights</span>
            <span className='line'></span>
          </Link>
        </div>

        <div className='menuIcon' onClick={() => setOpened(true)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div className={'menu ' + (opened ? 'opened' : '')}>
      <div className='closeIcon' onClick={() => setOpened(false)}>
        <span></span>
      </div>
      <div className='menuLinks'>
        <Link href='/' className={'link ' + (route === '/' ? 'active' : '')}>
          <span>About</span>
          <span>Twinpikes</span>
        </Link>
        {/* <Link href='/our-work' className={'link ' + (route === '/our-work' ? 'active' : '')}>
          <span>What</span>
          <span>We Do</span>
        </Link> */}
        <Link href='/our-team' className={'link ' + (route === '/our-team' ? 'active' : '')}>
          <span>Who</span>
          <span>We are</span>
        </Link>
        {/* <Link href='/our-spirit' className={'link ' + (route === '/our-spirit' ? 'active' : '')}>
          <span>How</span>
          <span>We do it</span>
        </Link> */}
        <Link href='/your-career' className={'link ' + (route === '/your-career' ? 'active' : '')}>
          <span>Your</span>
          <span>Career</span>
        </Link>
        {/* <Link href='/contact-us' className={'link ' + (route === '/contact-us' ? 'active' : '')}>
          <span>Get in</span>
          <span>Contact</span>
        </Link> */}
        <Link href='/our-insights' className={'link ' + (route === '/our-insights' ? 'active' : '')}>
          <span>Our</span>
          <span>Insights</span>
        </Link>
      </div>
    </div>
  </nav>
}