import '../styles/hero.scss';

export default function Hero({ video, title, text }: { video?: string, title?: any, text?: any }) {
  return <section className='hero'>
  <video className='video' autoPlay loop muted preload='true'>
    <source src={`/assets/video/${video || 'home'}.mp4`} type='video/mp4' />
  </video>
  <span className='shadow'></span>
  <div className='content'>
    <h1>{<>{title || <>&quot;Challenge the <span>Do&apos;s</span>&quot;</>}</>}</h1>
    <p>{<>{text || <><span>We</span> are a Management Consulting Firm helping our clients achieve ambitious results.</>}</>}</p>
  </div>
</section>
}