import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import '../styles/our-insights.scss';

export default function InsightPage() {
  const { insightId } = useParams<{ insightId: string }>();

  const [insight, setInsight] = useState<{
    id: string;
    title: string;
    byLine: string;
    lead: string;
    text: string[];
    closingLine: string;
    image?: string;
  }>();

  useEffect(() => {
    fetch('/data/insights/insights.json')
      .then(res => res.json())
      .then(data => {
        const i = data.find((insight: any) => insight.id === insightId);
        if (i) setInsight(data.find((insight: any) => insight.id === insightId))
        else window.location.href = '/our-insights';
      })
  }, [insightId]);

  return (
    <>
      <Navbar route="/our-insights" />

      <main>
        <Hero
          video="our-work"
          title='We do the right thing.'
          text={<><span>Our</span> deliverables are not just reports or presentations, but reaching results!</>}
        />

        <section className="insight__container">
          {
            insight?.image && <div className="img-mobile"><img src={`/data/insights/images/${insight.image}`} alt={insight.title} /></div>
          }
          <h3 className="title">{insight?.title}</h3>
          <span className="line"></span>
          <small className="byLine">{insight?.byLine}</small>
          <h5 className="lead">{insight?.lead}</h5>
          <div className="flex">
            <div className="content">
              {
                insight?.text.slice(0, 4).map((text, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: text }} className="text"></p>
                ))
              }
            </div>
            {
              insight?.image && <div className="img"><img src={`/data/insights/images/${insight.image}`} alt={insight.title} /></div>
            }
          </div>
          {
            // rest of the text
            insight?.text.slice(4).map((text, index) => (
              <p key={index} className="text" dangerouslySetInnerHTML={{ __html: text }}></p>
            ))
          }
          <p className="closingLine">{insight?.closingLine}</p>
        </section>
      </main>

      <Footer />
    </>
  )
}