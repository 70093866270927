import '../styles/footer.scss';
import logoGold from '../assets/img/logo_gold.svg';
import linkedIn from '../assets/img/linkedin.svg';

export default function Footer() {
  return <footer className='footer'>
    <div className='wrapper'>
      <a href='/' className='logo'>
        <img src={logoGold} alt='' />
      </a>

      <div className='offices'>
        <div className='office'>
          <h4>BARCELONA</h4>
          <p>Calle Muntaner 479, 2º 3º<br />08021 Barcelona Spain</p>
        </div>
        <div className='office'>
          <h4>MADRID</h4>
          <p>Calle Serrano, 93, 5A<br />28006 Madrid, Spain</p>
        </div>
        <div className='office'>
          <h4>LISBOA</h4>
          <p>Av. da República, 18, 3º<br />1050-191 Lisboa, Portugal</p>
        </div>
      </div>

      <div className='social'>
        <a href='https://www.linkedin.com/company/twinpikes/' target='_blank' rel='noreferrer'>
          <img src={linkedIn} alt='' />
        </a>
      </div>
    </div>
  </footer>
}