import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import '../styles/our-team.scss';
import linkedin from '../assets/img/linkedin_b.svg';
import backArrow from '../assets/img/back.svg';

export default function OurTeamPage() {
  const [opened, setOpened] = useState('');

  const [partners, setPartners] = useState<{
    name: string;
    description: string;
    longDescription: string[];
    image: string;
    linkedin: string;
  }[]>([]);

  const [directors, setDirectors] = useState<{
    name: string;
    description: string;
    longDescription: string[];
    image: string;
    linkedin: string;
  }[]>([]);

  const [managers, setManagers] = useState<{
    name: string;
    image: string;
  }[]>([]);

  const [pikers, setPikers] = useState<{
    name: string;
    image?: string;
  }[]>([]);

  useEffect(() => {
    fetch('/data/team/team.json')
      .then(res => res.json())
      .then(data => {
        setPartners(data.partners);
        setDirectors(data.directors);
        setManagers(data.managers);
        setPikers(data.pikers);
      });
  }, []);

  return (
    <>
      <Navbar route="/our-team" />

      <main>
        <Hero
          video="our-team"
          title='We are Ambitious and Passionate.'
          text={<><span>We</span> always think "how can we achieve even higher levels of performance?"</>}
        />

        <section className="team__heading">
          <p>Our Partners and Directors invest most of their time being actively involved in delivering projects, ensuring a proven return on investment and building trust and confidence in results flowing to the P&L.</p>
        </section>

        <section className="team__section">
          <h3>Partners & Senior Advisors</h3>
          <span className="line"></span>
          <div className="cards">
            {
              partners.map((partner, index) => (
                <div className="deck" key={index}>
                  <div className={"card" + (opened === `partners-${index}` ? ' flipped' : '')}>
                    <div className="face front">
                      <img src={`/data/team/images/${partner.image}`} alt={partner.name} />
                      <div className="content">
                        <h4 className="name">{partner.name}</h4>
                        <span className="line"></span>
                        <p>{partner.description}</p>
                        <small onClick={() => setOpened(`partners-${index}`)}>Read more</small>
                      </div>
                      <a href={partner.linkedin} rel='noreferrer' target={'_blank'}>
                        <img className="linkedin" src={linkedin} alt="" />
                      </a>
                    </div>
                    <div className="face back">
                      <div className="content">
                        {partner.longDescription.map((description, index) => (
                          <p key={index}>{description}</p>
                        ))}
                        <div className="backIcon" onClick={() => setOpened('')}>
                          <img src={backArrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        <section className="team__section">
          <h3>Directors</h3>
          <span className="line"></span>
          <div className="cards">
            {
              directors.map((director, index) => (
                <div className="deck" key={index}>
                  <div className={"card" + (opened === `director-${index}` ? ' flipped' : '')}>
                    <div className="face front">
                      <img src={`/data/team/images/${director.image}`} alt={director.name} />
                      <div className="content">
                        <h4 className="name">{director.name}</h4>
                        <span className="line"></span>
                        <p>{director.description}</p>
                        <small onClick={() => setOpened(`director-${index}`)}>Read more</small>
                      </div>
                      <a href={director.linkedin} rel='noreferrer' target={'_blank'}>
                        <img className="linkedin" src={linkedin} alt="" />
                      </a>
                    </div>
                    <div className="face back">
                      <div className="content">
                        {director.longDescription.map((description, index) => (
                          <p key={index}>{description}</p>
                        ))}
                        <div className="backIcon" onClick={() => setOpened('')}>
                          <img src={backArrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        <section className="team__section">
          <h3>Managers & Senior Consultants</h3>
          <span className="line"></span>
          <div className="cards _4inline">
            {
              managers.map((manager, index) => (
                <div className="card-alt">
                  <div className="face front">
                    <img src={`/data/team/images/${manager.image}`} alt={manager.name} />
                    <div className="content">
                      <h4 className="name alt">{manager.name}</h4>
                    </div>
                  </div>
                </div>
              ))
            }
            {
              pikers.filter(p => p.image).map((piker, index) => (
                <div className="card-alt">
                  <div className="face front">
                    <img src={`/data/team/images/${piker.image}`} alt={piker.name} />
                    <div className="content">
                      <h4 className="name alt">{piker.name}</h4>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        <section className="team__section">
          <h3>Pikers</h3>
          <span className="line"></span>
          <div className="cards _4inline">
            {
              pikers.filter(p => !p.image).map((piker, index) => (
                <div className="card-alt">
                  <div className="face front mar-all">
                    <div className="content">
                      <h4 className="name">{piker.name}</h4>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}