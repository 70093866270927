import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import '../styles/our-insights.scss';

export default function OurInsightsPage() {
  const [insights, setInsights] = useState<{
    id: string;
    title: string;
    byLine: string;
    lead: string;
    text: string[];
    closingLine: string;
    image?: string;
  }[]>([]);

  useEffect(() => {
    fetch('/data/insights/insights.json')
      .then(res => res.json())
      .then(data => setInsights(data))
  }, []);

  return (
    <>
      <Navbar route="/our-insights" />

      <main>
        <Hero
          video="our-work"
          title='We do the right thing.'
          text={<><span>Our</span> deliverables are not just reports or presentations, but reaching results!</>}
        />

        <section className="insights__heading">
          <p>Based on our experience, projects and solutions delivered all over the world, you can see below some of our leading thougths on many topics. Many brilliant ideas come from discussions and different points of view. <b>Count on us to help you Challenge the Do's!</b></p>
        </section>

        <section className="insights__container">
          {insights.map((insight, index) => (
            <div className="insight" key={index}>
              <h3>{insight.title}</h3>
              <span className="line"></span>
              <div>
                <a href={`/our-insights/${insight.id}`} className='button'>
                  <span>KNOW MORE</span>
                  <span className='line'></span>
                </a>
              </div>
            </div>
          ))}
        </section>
      </main>

      <Footer />
    </>
  )
}